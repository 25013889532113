






























































































































































































































































































































































































.studentNodes {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50rem;
    font-size: 14px;
  }
}
